import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Page/Home";
import About from "./Page/About";
import Services from "./Page/Services";
import Gallery from "./Page/Gallery";
import Contact from "./Page/Contact";
import Loader from "./components/global/Loader";
import "./index.css";
import "./assets/css/styles.css";
import "./assets/css/slider.css";
import "./assets/css/socialmediaicon.css";
import { GlobalDataContext } from "./context/context";
import ServicesDetail from "./components/Services/ServicesDetail";
import GalleryDetail from "./components/Gallery/GalleryDetail";
import Reviews from "./Page/Reviews";
import OurVideos from "./Page/OurVideos";
import FindUsOn from "./components/global/FindUsOn";
import ErrorPages from "./Page/ErrorPages";
import Login from "../src/components/reviews/Login";
import Logout from "../src/components/reviews/Logout/Logout";
import LoginWithEmail from "./components/reviews/LoginWithEmail";
import rpdata from "./data/rpdata.json";

const App = () => {
  const [isLoading] = useState(false);

  //useEffect para peticion al servidor

  // paletas de colores en la web
  document.documentElement.style.setProperty(
    "--main-color",
    rpdata.styles.mainColor
  );
  document.documentElement.style.setProperty(
    "--secondary-color",
     rpdata.styles.secondaryColor
  );
  document.documentElement.style.setProperty(
    "--three-color",
     rpdata.styles.thirdColor
  );
  document.documentElement.style.setProperty(
    "--heading-color",
     rpdata.styles.headingTextColor
  );
  document.documentElement.style.setProperty(
    "--paragraph-color",
     rpdata.styles.paragraphTextColor
  );
  document.documentElement.style.setProperty(
    "--bg-footer",
     rpdata.styles.bgFooter
  );
  document.documentElement.style.setProperty("--btn", rpdata.styles.btn);
  document.documentElement.style.setProperty(
    "--btn-hover",
   rpdata.styles.btnHover
  );
  document.documentElement.style.setProperty(
    "--scroll-color",
   rpdata.styles.scrollColor
  );
  document.documentElement.style.setProperty(
    "--icons-menu-movil",
   rpdata.styles.iconsMenuMovil
  );
  document.documentElement.style.setProperty(
    "--overlay-video-content",
   rpdata.styles.overlayColor
  );

  return isLoading ? (
    <Loader />
  ) : (
    <div className="App">
      <GlobalDataContext.Provider value={{ rpdata }}>
        <Router>
          {rpdata?.simpleWidgets?.[3]?.activo ? (
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="*" element={<ErrorPages />} />
            </Routes>
          ) : (
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />}></Route>
              <Route path="/:id" element={<ServicesDetail />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/gallery/:id" element={<GalleryDetail />} />
              <Route path="/reviews" element={<Reviews />} />
              <Route path="/our-videos" element={<OurVideos />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/find-us-on" element={<FindUsOn />} />
              <Route path="/find-us-on" element={<FindUsOn />} />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/login-email" element={<LoginWithEmail />} />
              <Route path="*" element={<ErrorPages />} />
            </Routes>
          )}
        </Router>
      </GlobalDataContext.Provider>
    </div>
  );
};

export default App;
